import React from "react";
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  DisabledInput,
  SimpleForm,
  TextInput,
  required
} from "react-admin";

export const DosyaList = (props: any) => (
  <List title="Uzman Listesi" {...props}>
    <Datagrid>
      <TextField label="Dosya Adi" source="name" />
    </Datagrid>
  </List>
);

export const DoktorEdit = (props: any) => (
  <Edit title="Uzman Duzenle" {...props}>
    <SimpleForm redirect="list">
      <DisabledInput source="id" />
      <TextInput label="Uzman Adi" validate={required()} source="name" />
      <TextInput label="Uzman Soyadi" validate={required()} source="lastname" />
      <TextInput label="Doktorun E-mail" validate={required()} source="email" />
    </SimpleForm>
  </Edit>
);

export const DoktorCreate = (props: any) => (
  <Create title="Uzman Ekle" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Uzman Adi" validate={required()} source="name" />
      <TextInput label="Uzman Soyadi" validate={required()} source="lastname" />
      <TextInput label="Uzman E-mail" validate={required()} source="email" />
    </SimpleForm>
  </Create>
);
