import React from "react";
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  DisabledInput,
  SimpleForm,
  TextInput,
  required
} from "react-admin";

export const TestList = (props: any) => (
  <List title="Testler" {...props}>
    <Datagrid>
      <TextField label="Testin Adi" source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

export const TestEdit = (props: any) => (
  <Edit title="Test Duzenle" {...props}>
    <SimpleForm redirect="list">
      <DisabledInput source="id" />
      <TextInput label="Testin Adi" validate={required()} source="name" />
    </SimpleForm>
  </Edit>
);

export const TestCreate = (props: any) => (
  <Create title="Test Ekle" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Testin Adi" validate={required()} source="name" />
    </SimpleForm>
  </Create>
);
