import React from "react";
import "./App.css";
import { Admin, Resource, Login } from "react-admin";
import { HastaCreate, HastaList, HastaDuzenle } from "./Hasta";
import { DoktorList, DoktorCreate, DoktorEdit } from "./Doktor";
import { TestCreate, TestEdit, TestList } from "./Testler";
import turkishMessages from "ra-language-turkish";
import {
  FirebaseRealTimeSaga,
  FirebaseDataProvider,
  FirebaseAuthProvider,
  RAFirebaseOptions
} from "react-admin-firebase";
import { DosyaList } from "./Dosya";

const messages = {
  tr: turkishMessages
};
const i18nProvider = () => messages.tr;

const config = {
  apiKey: "AIzaSyCfNuCn40TAQB2cxHqq4tH0sxslPeMbEzg",
  authDomain: "psi-archive.firebaseapp.com",
  databaseURL: "https://psi-archive.firebaseio.com",
  projectId: "psi-archive",
  storageBucket: "psi-archive.appspot.com",
  messagingSenderId: "931722555774",
  appId: "1:931722555774:web:1c0890129a53b037"
};

const options: RAFirebaseOptions = {
  logging: true
};
const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);
const firebaseRealtime = FirebaseRealTimeSaga(dataProvider, options);

const MyLoginPage = () => (
  <Login backgroundImage="https://images.unsplash.com/photo-1464037880320-be5f34df1397?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80" />
);

const App: React.FC = () => {
  return (
    <Admin
      locale="tr"
      i18nProvider={i18nProvider}
      loginPage={MyLoginPage}
      customSagas={[firebaseRealtime]}
      dataProvider={dataProvider}
      authProvider={authProvider}
    >
      <Resource
        options={{
          label: "Danışanlar"
        }}
        name="hastalar"
        list={HastaList}
        create={HastaCreate}
        edit={HastaDuzenle}
      />
      <Resource
        options={{
          label: "Testler"
        }}
        name="testler"
        list={TestList}
        create={TestCreate}
        edit={TestEdit}
      />
      <Resource
        options={{
          label: "Uzmanlar"
        }}
        name="doktorlar"
        list={DoktorList}
        create={DoktorCreate}
        edit={DoktorEdit}
      />
      <Resource
        options={{
          label: "Dosyalar"
        }}
        name="dosyalar"
        list={DosyaList}
      />
    </Admin>
  );
};

export default App;
